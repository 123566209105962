import React, { Fragment } from "react";
import Hero from "../Components/Hero";
import "../Styles/app.scss";
import Work from "./Work";
import Contact from "./Contact";
import AboutMe from "./AboutMe";
import Projects from "./Projects";

export default function Home() {
  return (
    <Fragment>
      <Hero />
      <AboutMe />
      <Projects />
      <Work />
      <Contact />
    </Fragment>
  );
}
