import React from "react";
import { faSpotify, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PodcastLink.scss";
import { faPodcast } from "@fortawesome/free-solid-svg-icons";
import { analytics } from "../../firebase";

export default function PodcastLinks() {
  return (
    <section className="links-container">
      <div class="hero-podcast">
        <div class="content">
          <h1 className="txt-white ">Qué Dirían Podcast</h1>
          <p className="txt-white ">
            El único show donde compartimos nuestra opinión aunque nadie nos la
            pida, sin importar que tan importante o irrelevante sea el tema.{" "}
          </p>
        </div>
      </div>
      <h1 className="txt-primary ">Que Dirían Podcast</h1>
      <h2 className="txt-secondary ">Escuchanos en:</h2>
      <article className="links">
        <a
          href="https://open.spotify.com/show/35L0pT8iiaiOOVo6j1D0Zx"
          target="_blank"
          rel="noreferrer"
          className="link-card spotify"
          onClick={() => analytics.logEvent("podcast_view_spotify")}
          // onClick={goTo("https://open.spotify.com/show/35L0pT8iiaiOOVo6j1D0Zx")}
        >
          <span>
            <FontAwesomeIcon className="txt-white" size="4x" icon={faSpotify} />
          </span>

          <p>Spotify</p>
        </a>
        <a
          href="https://podcasts.apple.com/mx/podcast/que-dirian/id1536357504"
          target="_blank"
          rel="noreferrer"
          className="link-card apple"
          onClick={() => analytics.logEvent("podcast_view_apple")}
        >
          <span>
            <FontAwesomeIcon className="txt-white" size="4x" icon={faPodcast} />
          </span>

          <p>Apple Podcast</p>
        </a>
        <a
          href="https://www.youtube.com/channel/UCAUK57s98G9us5lMK5UnZpg"
          target="_blank"
          rel="noreferrer"
          className="link-card youtube"
          onClick={() => analytics.logEvent("podcast_view_youtube")}
        >
          <span>
            <FontAwesomeIcon className="txt-white" size="4x" icon={faYoutube} />
          </span>

          <p>YouTube</p>
        </a>
      </article>

      <div className="redcirclePlayer-ebebdd01-ddb4-40d6-9420-4e5ed9484076 player" />

      <p
        style={{
          marginTop: "3px",
          marginLeft: "11px",
          fontFamily: "sans-serif",
          fontSize: "10px",
          color: "gray",
        }}
      >
        Powered by{" "}
        <a
          className="redcircle-link"
          href="https://redcircle.com?utm_source=rc_embedded_player&utm_medium=web&utm_campaign=embedded_v1"
        >
          RedCircle
        </a>
      </p>
    </section>
  );
}
