import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APYKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

firebase.initializeApp(config);

const storage = firebase.storage();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

export { storage, firestore, analytics, firebase as default };
