import React, { useEffect } from "react";
import "./Modal.scss"; // Importa el archivo SCSS actualizado
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const Modal = ({ isOpen, children, onClose }) => {
  useEffect(() => {
    // Cuando el modal se abre, desactiva el scroll en el body
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    // Cuando el modal se cierra, restablece el scroll
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]); // Este efecto se ejecuta sólo cuando isOpen cambia

  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="content">
          <div className="back">
            <div className="back-content">
              <div className="close-section">
                <Button
                  type="text"
                  style={{ color: "white" }}
                  onClick={onClose}
                  icon={<CloseOutlined />}
                />
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
