import React from 'react'
import Header from './NavBar'
import Footer from './Footer'
import "../Styles/app.scss"

export default function Layout(props) {
  return (
    <div className='layout'>
      <Header />
      <main className='main'>{props.children}</main>
      <Footer/>
    </div>
  )
}
