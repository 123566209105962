import React from "react";

export default function Mars() {
  return (
    <main
      style={{
        overflow: "auto",
        textAlign: "center",
      }}
    >
      <iframe
        title="Mars"
        width="980"
        height="410"
        src="https://mars.nasa.gov/layout/embed/send-your-name/future/certificate/?cn=814863999531"
        frameborder="0"
      ></iframe>
    </main>
  );
}
