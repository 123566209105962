import React, { useState, useEffect } from "react";
import { Menu, Layout, Image } from "antd";
import {
  MenuOutlined,
  HomeOutlined,
  ReadOutlined,
  UserOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import Logo from "../Assets/images/Logo.png";
import "../Styles/NavBar.scss";

const { Header } = Layout;

const menuItems = [
  {
    label: <a href="/">Home</a>,
    key: "home",
    icon: <HomeOutlined />,
    path: "/",
  },

  {
    label: <a href="/about">About Me</a>,
    key: "about",
    icon: <UserOutlined />,
    path: "/about",
  },
  {
    label: <a href="/projects">Projects</a>,
    key: "projects",
    icon: <LaptopOutlined />,
    path: "/projects",
  },
  {
    label: <a href="https://blog.noeosorio.com/">Blog</a>,
    key: "blog",
    icon: <ReadOutlined />,
    path: "/blog",
  },
];

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setMenuOpen(false); // Cierra el menú al cambiar el tamaño de la pantalla
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Header className="header">
        {isMobile && (
          <MenuOutlined
            onClick={() => setMenuOpen(!menuOpen)}
            style={{
              fontSize: "24px",
              lineHeight: "64px",
              cursor: "pointer",
            }}
          />
        )}
        <a href="/" className="logo">
          <Image
            alt="Noe Osorio Logo"
            preview={false}
            className="logo-img"
            src={Logo}
          ></Image>
          <h3 className="txt-primary ">Noe Osorio</h3>
        </a>
        {!isMobile && (
          <Menu
            className="menu"
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            style={{ lineHeight: "64px" }}
            items={menuItems}
          ></Menu>
        )}
      </Header>
      <Menu
        theme="dark"
        mode={isMobile ? "vertical" : "horizontal"}
        defaultSelectedKeys={["1"]}
        style={{ lineHeight: "64px" }}
        className={
          isMobile ? (menuOpen ? "mobileMenu opened" : "mobileMenu") : ""
        }
        items={menuItems}
      ></Menu>
    </div>
  );
};

export default Navbar;
