import React from "react";
import "../Styles/hero.scss";
import HeroImage from "../Assets/images/Hero.png";
import { Image } from "antd";
import { Button } from "./Buttons";

function Hero() {
  return (
    <section className="hero">
      <div className="hero-text">
        <h2>
          I'm Noé, a seasoned <strong>Software Engineer</strong> specializing in <strong>React</strong>, <strong>Flutter</strong>,
          and <strong>Node.js</strong>.
        </h2>
        <h3>
          Crafting Innovative <strong>Web</strong> & <strong>Mobile</strong> Solutions | Experienced in Tech 
          <strong> Startups</strong> and <strong>Mental Health</strong> Tech 
        </h3>
        <Button
          action={() => {
            window.open(
              "https://calendly.com/noeosorio/tech-consultation-mentorship",
              "_blank"
            );
          }}
        >
          Book a Consultation Now
        </Button>
      </div>
      <div className="hero-img">
        <Image alt="Hero UI Mockup" preview={false}  src={HeroImage}></Image>
      </div>
    </section>
  );
}

export default Hero;
