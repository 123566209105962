import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { loadMarkdownFile } from "../../Services/blog.service";
import { useParams } from "react-router-dom";
import "./Blog.scss";

export default function BlogPost() {
  const [post, setPost] = useState(null);
  const { id: postId } = useParams();
  useEffect(() => {
    loadMarkdownFile(postId).then(setPost);
  }, [postId]);
  if (!post?.md) {
    return <h1>404</h1>;
  }
  const { md, title, subtitle } = post;

  function contarPalabras(texto) {
    return texto.split(/\s+/).length;
  }
  const numeroDePalabras = contarPalabras(md);
  const palabrasPorMinuto = 150; // Puedes ajustar esto según tu público objetivo
  const tiempoDeLectura = Math.ceil(numeroDePalabras / palabrasPorMinuto);

  return (
    <div className="blog-container">
      <h1 className="blog-title">{title}</h1>
      <h2 className="blog-subtitle">{subtitle}</h2>
      <div>{tiempoDeLectura} min. read</div>
      <ReactMarkdown>{md}</ReactMarkdown>
    </div>
  );
}
