import { firestore, storage } from "../firebase";

export async function getBlogPosts() {
  const snapshot = await firestore.collection("posts").get();
  return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

export const loadMarkdownFile = async (postId) => {
  try {
    const doc = await firestore.collection("posts").doc(postId).get();
    if (doc.exists) {
      const fileRef = storage.refFromURL(doc.data().filePath);
      const fileUrl = await fileRef.getDownloadURL();
      const response = await fetch(fileUrl);
      const markdownText = await response.text();
      return { ...doc.data(), md: markdownText };
    } else {
      console.log("No se encontró el documento!");
    }
  } catch (error) {
    console.error("Error al cargar el documento", error);
    return null;
  }
};
