import React from "react";

import {
  faGithub,
  faGitlab,
  faInstagram,
  faLinkedin,
  faTwitter,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBriefcase,
  faCode,
  faEnvelope,
  faPodcast,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";

import "../Styles/app.scss";
import "../Styles/contact.scss";

export default function Social() {
  return (
    <div className="container">
      <Row>
        <Col span={24}>
          <h2 className="txt-secondary">Business</h2>
        </Col>

        <SocialMedia href="https://es.fiverr.com/noeosorioh" icon={faBriefcase}>
          fiverr.com/noeosorioh
        </SocialMedia>
        <SocialMedia
          href="https://www.linkedin.com/in/noeosorioh/"
          icon={faLinkedin}
        >
          /noeosorioh
        </SocialMedia>
        <SocialMedia href="https://t.me/noeosorio" icon={faTelegram}>
          t.me/noeosorio
        </SocialMedia>
        <SocialMedia href="mailto:business@noeosorio.com" icon={faEnvelope}>
          business@noeosorio.com
        </SocialMedia>
      </Row>
      <Row>
        <Col span={24}>
          <h2 className="txt-secondary">Social Media</h2>
        </Col>

        <SocialMedia
          href="https://podcasts.apple.com/mx/podcast/que-dirian/id1536357504"
          icon={faPodcast}
        >
          Que Dirian Podcast
        </SocialMedia>

        <SocialMedia href="https://twitter.com/noeosorioh" icon={faTwitter}>
          @noeosorioh
        </SocialMedia>

        <SocialMedia
          href="https://www.instagram.com/noeosorio.dev"
          icon={faInstagram}
        >
          @noeosorio.dev
        </SocialMedia>

        <SocialMedia href="https://dev.to/_noeos" icon={faCode}>
          dev.to/_noeos
        </SocialMedia>

        <SocialMedia href="https://github.com/NoeOsorio" icon={faGithub}>
          /NoeOsorio
        </SocialMedia>

        <SocialMedia href="https://gitlab.com/osnoe" icon={faGitlab}>
          /osnoe
        </SocialMedia>
      </Row>
    </div>
  );
}

function SocialMedia(props) {
  return (
    <Col xs={24} sm={20} lg={12}>
      <a
        href={props.href || "#"}
        target="_blank"
        rel="noopener noreferrer"
        className="social-media"
      >
        <i>
          <FontAwesomeIcon
            className="txt-white"
            size="2x"
            icon={props.icon || null}
          />
        </i>
        <strong className=""> {props.children}</strong>
      </a>
    </Col>
  );
}
