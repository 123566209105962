import React from "react";
import { Image } from "antd";
import profile from "../Assets/images/noe-profile.jpg";
import { MediumButton } from "../Components/Buttons";
import "../Styles/aboutMe.scss";

export default function AboutMe() {
  return (
    <section className="aboutMe">
      <Image alt="Noe Osorio face" preview={false} src={profile}></Image>
      <div className="aboutMe-text">
        <h2>About me</h2>
        <p>
          Hey there! 👋 I'm Noé, your go-to guy for anything related to Web and
          Mobile development. Graduated in Computer Science from Benemérita
          Universidad Autónoma De Puebla, and since then, I've been diving into
          the tech world, from cool startups to projects that make a difference
          in mental health.
        </p>
        <p>
          Languages are my jam — fluent in English and Spanish, and dabbling in
          Japanese (big anime fan here, so it's a must!). Speaking of which, if
          you've got any anime recommendations, send them my way!
        </p>
        <p>
          When I'm not decoding the mysteries of tech, I'm likely whipping up
          something tasty (love exploring new recipes), or out and about with my
          adorable four-legged sidekick, Shina. She's the best walking buddy and
          a pro at getting me away from the computer screen.
        </p>
        <p>
          So, that's a bit about me. I love turning complex tech jargon into fun
          chats. Got an idea or just wanna geek out over the latest tech and
          anime? Let's connect and make something awesome happen!
        </p>
        <div className="aboutMe-buttons">
          {/* TODO: Use link to real url */}
          <MediumButton
            action={() => {
              window.open(
                "https://drive.google.com/file/d/1KDqik6bGtVPKGTZro_zimZlWLJJDbNBJ/view?usp=sharing",
                "_blank"
              );
            }}
          >
            Resume
          </MediumButton>
          <MediumButton
            action={() => {
              window.open(
                "https://calendly.com/noeosorio/tech-consultation-mentorship",
                "_blank"
              );
            }}
            outline
          >
            Contact
          </MediumButton>
        </div>
      </div>
    </section>
  );
}
