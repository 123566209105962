import React from "react";
import "../Styles/Work.scss";
import workData from "../Data/work.json";
import ProjectDisplay from "../Components/ProjectDisplay";

export default function Projects() {
  return (
    <section id="profesional-experience" className="work">
      <h1 className="txt-primary">Professional Experience</h1>

      <div className="project-container">
        {Object.keys(workData).map((key) => {
          return (
            <ProjectDisplay
              key={workData[key].title || `${key}-project`}
              img={workData[key].img}
              desc={workData[key].desc}
              title={workData[key].title}
              position={workData[key].position}
              company={workData[key].company}
              companyLink={workData[key].companyLink}
              technologies={workData[key].technologies}
              backgroundColor={workData[key].backgroundColor}
            ></ProjectDisplay>
          );
        })}
      </div>
    </section>
  );
}


