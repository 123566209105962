import React from "react";
import ProjectDisplay from "../Components/ProjectDisplay";
import portfolioData from "../Data/portfolio.json";

export default function Projects() {
  return (
    <section id="profesional-experience" className="work">
      <h1 className="txt-primary">Personal Projects</h1>

      <div className="project-container">
        {Object.keys(portfolioData).map((key) => {
          return (
            <ProjectDisplay
              key={portfolioData[key].title || `${key}-project`}
              img={portfolioData[key].img}
              desc={portfolioData[key].desc}
              title={portfolioData[key].title}
              position={portfolioData[key].position}
              company={portfolioData[key].company}
              companyLink={portfolioData[key].companyLink}
              technologies={portfolioData[key].technologies}
              backgroundColor={portfolioData[key].backgroundColor}
              descriptions={portfolioData[key].descriptions}
            ></ProjectDisplay>
          );
        })}
      </div>
    </section>
  );
}