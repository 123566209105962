import React from "react";


import Social from "../Components/Social";

export default function Contact() {
  return (
    <section id="contact">
      <h1 className="txt-primary">Contact</h1>
      <Social />
    </section>
  );
}
