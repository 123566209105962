import React from "react";

const Privacy = () => {
  return (
    <div>
      <h1>Política de Privacidad para "100 Yenes"</h1>
      <h2>Introducción</h2>
      <p>
        Bienvenido a "100 Yenes" ("nosotros", "nuestro"), la aplicación diseñada
        para explorar la riqueza y el diseño de la moneda de 100 yenes
        japoneses. Su privacidad es de suma importancia para nosotros. Esta
        Política de Privacidad explica cómo recopilamos, usamos, compartimos y
        protegemos la información en relación con nuestra aplicación móvil,
        sitio web, y cualquier servicio asociado (referidos colectivamente como
        el "Servicio").
      </p>
      <p>
        Al utilizar nuestro Servicio, usted comprende y acepta la recopilación y
        el uso de información de acuerdo con esta política. A menos que se
        defina lo contrario en esta Política de Privacidad, los términos
        utilizados en esta Política tienen los mismos significados que nuestros
        Términos y Condiciones.
      </p>
      <h2>Información que Recopilamos</h2>
      <p>Recopilamos la siguiente información sobre usted:</p>
      <ul>
        <li>
          Información de Identificación Personal: Nombre, dirección de correo
          electrónico, número de teléfono, dirección postal.
        </li>
        <li>
          Datos de Uso y Dispositivo: Cómo accede y utiliza nuestro Servicio,
          incluidas la fecha y hora de acceso, el tipo de dispositivo, el
          sistema operativo, la configuración del dispositivo, identificadores
          únicos del dispositivo, y datos de la red.
        </li>
        <li>
          Información de Localización: Su ubicación precisa si nos da permiso a
          través de la configuración de su dispositivo.
        </li>
        <li>
          Información Recopilada a través de Cookies y Tecnologías de
          Seguimiento: Información sobre su navegación en nuestro Servicio,
          incluidas las páginas que visita y cómo interactúa con esas páginas.
        </li>
      </ul>
      <h2>Cómo Utilizamos Su Información</h2>
      <p>Usamos la información que recopilamos sobre usted para:</p>
      <ul>
        <li>
          Proporcionar, mantener, y mejorar nuestro Servicio, incluyendo para
          realizar análisis internos y poder ofrecerle una experiencia
          personalizada.
        </li>
        <li>
          Comunicarnos con usted, incluyendo para enviarle actualizaciones del
          Servicio y respuestas a sus preguntas.
        </li>
        <li>
          Realizar investigaciones y desarrollo para mejorar nuestro Servicio y
          desarrollar nuevos productos.
        </li>
        <li>
          Detectar, investigar y prevenir actividades fraudulentas, abuso y
          violaciones de nuestros Términos y Condiciones.
        </li>
      </ul>
      <h2>Compartición de Información</h2>
      <p>
        Solo compartimos su información personal con terceros en las siguientes
        circunstancias:
      </p>
      <ul>
        <li>
          Con Su Consentimiento: Compartiremos información personal con terceros
          cuando tengamos su consentimiento explícito para hacerlo.
        </li>
        <li>
          Para Proveedores de Servicios: Compartimos información con empresas o
          individuos que nos ayudan a operar nuestro Servicio, como hosting,
          análisis de datos, marketing, publicidad, y servicios de atención al
          cliente.
        </li>
        <li>
          Por Razones Legales: Revelaremos información personal cuando sea
          razonablemente necesario para cumplir con una ley, regulación, proceso
          legal o solicitud gubernamental aplicable.
        </li>
      </ul>
      <h2>Sus Derechos</h2>
      <p>
        Usted tiene derecho a acceder, corregir o eliminar su información
        personal que tenemos. También puede tener derecho a oponerse al
        procesamiento de su información personal, a solicitar la limitación de
        dicho procesamiento y a solicitar la portabilidad de su información
        personal.
      </p>
      <h2>Seguridad de los Datos</h2>
      <p>
        La seguridad de su información es importante para nosotros, pero
        recuerde que ningún método de transmisión por Internet o método de
        almacenamiento electrónico es 100% seguro. Aunque nos esforzamos por
        utilizar medios comercialmente aceptables para proteger su información
        personal, no podemos garantizar su seguridad absoluta.
      </p>
      <h2>Cambios a Esta Política de Privacidad</h2>
      <p>
        Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le
        notificaremos de cualquier cambio publicando la nueva Política de
        Privacidad en esta página y, si los cambios son significativos, le
        proporcionaremos un aviso más prominente.
      </p>
      <h2>Contacto</h2>
      <p>
        Si tiene alguna pregunta sobre esta Política de Privacidad, por favor
        contáctenos en business@noeosorio.com.
      </p>
    </div>
  );
};

export default Privacy;
